<template>
    <v-dialog width="500" transition="dialog-bottom-transition"  v-model="visible">
        <v-card class="align-center">
            <div v-if="!disableIcon" class="svgbox">
                <img class="align-center" :src="iconUrl"/>
            </div>
            <div class="text-h2 text-center" :class="`${disableIcon ? 'mt-4': ''}`">
                {{title}}
            </div>
            <v-card-text :class="continueBtn || cancelBtn ? 'text-h5 text-center' : 'text-h5 mb-6 text-center'">
                    {{ text }}
            </v-card-text>
            <div>
                <template v-if="btnPosition == 'default'">
                <v-btn :color="continueBtnColor" size="large" class="mx-2 my-4" v-if="continueBtn" @click="actionClick">{{ continueText }}</v-btn>
                <v-btn :color="cancelBtnColor" size="large" class="mx-2 my-4" v-if="cancelBtn" @click="closeModal()">{{ cancelText }}</v-btn>
                </template>
                <template v-else-if="btnPosition == 'inverse'">
                <v-btn :color="cancelBtnColor" size="large" class="mx-2 my-4" v-if="cancelBtn" @click="closeModal()">{{ cancelText }}</v-btn>
                <v-btn :color="continueBtnColor" size="large" class="mx-2 my-4" v-if="continueBtn" @click="actionClick">{{ continueText }}</v-btn>
                </template>
            </div>
        </v-card>
    </v-dialog>
</template>

<script setup lang="ts">
const {isModalOpen,modalOptions,modal,closeModal} = useGui();
const props = defineProps({
    config: {type: Object},
    show: {type:Boolean},
    backdrop: {type: Boolean, default: true},
    closeOnBackdrop: {type: Boolean, default: true},
    addContentClasses: [String, Array, Object],
});

const showAlert = ref(props.show);
const visible = ref(isModalOpen.value);
const isTransitioning = ref(false);
const timeout = ref(null);
const reference = ref({title: '', text: '', centered: true, btnPosition:'default', disableIcon: false, icon: 'success', size: 'md', continueBtn: false, continueText: 'Continue', continueBtnColor: 'info', cancelBtn: true, cancelText: 'Cancel', cancelBtnColor:'inputBorder'},);
const options = ref(modalOptions())
const title = computed(() => {
    return options.value.title;
});
const btnPosition = computed(() => {
    return options.value.btnPosition;
});
const text = computed(() => {
    return options.value.text;
});
const continueBtn = computed(() => {
    return options.value.continueBtn;
});
const continueText = computed(() => {
    return options.value.continueText;
});
const cancelBtn = computed(() => {
    return options.value.cancelBtn;
});
const cancelText = computed(() => {
    return options.value.cancelText;
});
const centered = computed(() => {
    return options.value.centered;
});
const icon = computed(() => {
    return options.value.icon;
});
const size = computed(() => {
    return options.value.size;
});
const disableIcon = computed(()=>{
    return options.value.disableIcon;
});
const continueBtnColor = computed(()=>{
    return options.value.continueBtnColor;
});
const cancelBtnColor = computed(()=>{
    return options.value.cancelBtnColor;
});

const iconUrl = computed(() => {
    return `/images/event/${icon.value}.svg`
});

watch(modal.value, () => {
    options.value = modal.value.options;
    visible.value = modal.value.isOpen;
    
});

const actionClick = (e : any) => {
          if(modal.value?.actionCallback?.continue) {
            modal.value?.actionCallback?.continue.callback();
            closeModal();
          }
          else {
            closeModal();
          }
    }
</script>

<style scoped>
.svgbox {
    display:inline-block;
    position:relative;
    width:150px;
}
</style>