//@ts-nocheck
import {useState} from "#app";
// import {useAuthStore} from "~/stores/core";

type ActionCallback = {
  continue?: {
    callback: () => void;
  };
};

type ModalData = {
  isOpen: boolean;
  options: {
    title: string;
    text: string;
    centered: boolean;
    btnPosition: string;
    disableIcon: boolean;
    icon: string;
    size: string;
    continueBtn: boolean;
    continueText: string;
    continueBtnColor: string;
    cancelBtn: boolean;
    cancelText: string;
    cancelBtnColor: string;
  };
  backdrop: boolean;
  closeOnBackdrop: boolean;
  actionCallback: ActionCallback;
};

type Alert = {
  title: string;
  text: string;
  icon: string;
  showCancelButton: boolean;
  confirmButtonText?: string;
  showConfirmButton?: boolean;
  disableIcon?: boolean;
  continueBtnColor?: string;
  cancelBtnColor?: string;
  btnPosition?: string;
};
const useGui = () => {
  let modalData: ModalData = {
    isOpen: false,
    options: {
      title: '',
      text: '',
      centered: true,
      btnPosition: 'default',
      disableIcon: false,
      icon: 'success',
      size: 'md',
      continueBtn: false,
      continueText: 'Continue',
      continueBtnColor: 'info',
      cancelBtn: true,
      cancelText: 'Cancel',
      cancelBtnColor: 'inputBorder'
    },
    backdrop: true,
    closeOnBackdrop: true,
    actionCallback: {}
  }
  const isModalOpen = useState('isModalOpen', () => false)
  const modal = useState('modal', () => modalData);
  const itemPageSelect = useState('itemPageSelect', () => 25);
  const rowsItems = useState('rowsItems', () => [10, 25, 50, 100, 150, 200]);
  const modalOptions = () => {
    return modal.value.options;
  }
  const closeModal = () => {
    modal.value.isOpen = false;
    modal.value.actionCallback = {};
  }
  const showModalAlert = (alert: Alert, callback: ((id: number) => void) | null = null): void => {
    let config: {actionCallback?: ActionCallback} = {};
    let options = {
      title: '',
      text: '',
      centered: true,
      btnPosition: 'default',
      disableIcon: false,
      icon: 'error',
      size: 'md',
      continueBtn: true,
      continueText: 'Ok',
      continueBtnColor: 'info',
      cancelBtn: false,
      cancelText: 'Cancel',
      cancelBtnColor: 'inputBorder'
    };
    options.title = alert.title;
    options.text = alert.text;
    options.icon = alert.icon;
    options.cancelBtn = alert.showCancelButton;
    options.continueText = alert.confirmButtonText ?? "Ok";
    options.continueBtn = alert.showConfirmButton ?? true;
    options.disableIcon = alert.disableIcon ?? options.disableIcon;
    options.continueBtnColor = alert.continueBtnColor ?? options.continueBtnColor;
    options.cancelBtnColor = alert.cancelBtnColor ?? options.cancelBtnColor;
    options.btnPosition = alert.btnPosition ?? options.btnPosition;

    if (callback != null) {
      config.actionCallback = {continue: {callback: callback}}
    }
    modal.value.options = options;
    modal.value.actionCallback = config.actionCallback ?? {};
    modal.value.isOpen = true;
  }
  const setItemPageSelect = (value) => {
    const {getUserProfile, setUserProfile} = useAuthStore();
    const {setSettings} = useAuth();
    itemPageSelect.value = value;
    if (getUserProfile && getUserProfile.user?.settings) {
      const userProfile = getUserProfile;
      userProfile.user.settings['pagination'] = itemPageSelect.value;
      setUserProfile(userProfile);
      setSettings();
    }

  }
  return {isModalOpen, modal, itemPageSelect, rowsItems, setItemPageSelect, modalOptions, closeModal, showModalAlert};
}
export default useGui;